import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/parcel-forwarding.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Why your business should invest in parcel forwarding
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Nov 7, 2020
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Australia Products" className={styles.titleImage} />
                                    </div>
                                        <p>Parcel forwarding is a necessity for every modern-day business. This is due to the reality that most clients are always shifting their addresses, whether it be due to work or personal reasons. Hence, it is more cost-effective and time-saving to entrust parcel distribution to these professionals. But what exactly should you expect as a business?</p>
                                        <p>This article will orient you on the benefits of investing in parcel forwarding services. We will also discuss what kind of viable business opportunities you can get into. The main goal here is to maximise mail redirection services and discuss lucrative opportunities to increase your business profitability, such as global services and service-oriented branding.</p>
                                        <p>Here’s why your business should invest in parcel forwarding services:</p>
                                        <p><strong>1 – Ensures the security of your clients and their parcels</strong></p>
                                        <p>The use of traditional postal services comes with some personal security risk. This is due to the vulnerable details of clients being easily accessible to different individuals, which has resulted in identity theft and the damage of personal belongings. Fortunately, this is not the case for modern-day parcel forwarding.</p>
                                        <p>Parcel forwarding services differentiate themselves by taking special care when it comes to security. They make use of different security protocols and ensure professional handling for all client packages. This enables you as a business to provide high-quality services and trustworthiness to your customers. Additionally, it allows you to secure your professional client-to-business relationship, even when clients move to different locations.</p>
                                        <p><strong>2 – Allows your business to accommodate to different customers worldwide</strong></p>
                                        <p>Many modern-day businesses are largely restricted to certain service areas due to logistic challenges. This means they are only able to bring their products and services to a select few. This&nbsp; can also be detrimental to your business’ scalability and profitability, since your current service location may be in a largely stagnant market area.</p>
                                        <p>Parcel forwarding services opens opportunities for your business to secure different service areas through accommodating different customers, even when they change their shipping address. You as a business are then able to accomplish different kinds of orders worldwide. Most importantly, this gives you better insight on the potential expansion of your business to other locations.</p>
                                        <p><strong>3 – Provides easy online communication for customers</strong></p>
                                        <p>Modern-day online shopping has forced postal services to also adapt to online communications. Nowadays, most customers want to get notifications about their parcels such as current status and location. This gives them the ability to effectively track their shipments and anticipate its arrival.</p>
                                        <p>Modern-day parcel forwarding services&nbsp; provide online notifications to your customers. This enables them to personally oversee the parcel’s expected delivery date and immediately request for changes, if necessary. This also means that you as a business can focus on other aspects of customer care, like product satisfaction and market profiling.</p>
                                        <p><strong>4 – Maintains your business’ professionalism and service-oriented attitude</strong></p>
                                        <p>Modern-day businesses experience many different challenges when it comes to their logistics. This is due to the volume of orders received and pressure to deliver them. Errors can occur as a result, like mismatched customer information or damaged parcels.</p>
                                        <p>Parcel forwarding preserves the professionalism of business logistics through their service-orientedness and wider capacity to deliver to as many customers. Their expertise and professional solutions allow them to successfully fulfill the safe arrival of your business parcel. Most importantly, you can avoid having to deal with different logistical issues yourself, such as sudden address changes and costly mail redirection fees.</p>
                                        <p><strong>Conclusion</strong></p>
                                        <p>Parcel forwarding preserves your business’ dedication to personal security and high-quality customer service. It allows you to keep to your professional standards while being able to free up your capability to do other important business tasks. Invest in parcel forwarding services today to streamline your professional order fulfillment.</p>
                                        <p>Are you in need of a highly professional and digitized international <a href="http://hotsnail.com.au/">parcel forwarding services</a> for your business in Australia? We at HotSnail can provide you with that. We have different mail redirection packages and services to ensure that you and your customers get their parcels delivered. Consult with us today for specialised logistics solutions for your business parcel!</p>

                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Business</BadgePost>
                                    <BadgePost>Parcel forwarding</BadgePost>
                                    <BadgePost>Mail Scanning</BadgePost>
                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
